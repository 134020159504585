<template>
<div class="row px-0">
    <div class="col-12 pt-2 px-0">
        <table id="basic-datatable" class="table table-striped  dt-responsive nowrap db"></table>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import firebase from "firebase/app"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import 'busy-load';

// import "datatables.net"
require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-select-bs4')
require('datatables.net-bs4')
require('lightbox2/dist/js/lightbox.js')
require('lightbox2/dist/css/lightbox.css')

import Swal from 'sweetalert2'
export default {
    data() {
        return {
            // accountList : {}
        }
    },
    mounted() {
        let vue = this
        let columns = [{
                data: 'logo',
                title: "Logo",
                width: 20,
                render: function (data, type, row) {
                    return `<a href="${data}" data-lightbox="image-1"><img src="${data}" width=50 ></a>`
                }
            },
            {
                data: 'name',
                title: "Name"
            },
            {
                data: 'description',
                title: "Description"
            },
            {
                data: 'genre',
                title: "Genre"
            },
            {
                data: 'host',
                title: "Host"
            },
            {
                data: 'type',
                title: "Type"
            },
            {
                data: 'regis',
                title: "Enroll Date"
            },
            {
                data: 'update',
                title: "Last Update"
            },
            {
                data: 'status',
                title: "Status",
                render: function (data, type, row) {
                    if(data === "ACTIVE")
                        return `<span class='text-success'>${data}</span>`
                    else if(data === "REJECT")
                        return `<span class='text-danger'>${data}</span>`
                    else
                        return `<span class='text-warning'>${data}</span>`
                }
            },
        ]
        let table = $('#basic-datatable').DataTable({
            order: [
                [6, "desc"]
            ],
            pageLength: 50,
            lengthChange: !1,
            responsive: true,
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            columns: columns,
            select: 'single',
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all",
                "className": "align-middle",
            }],
            buttons: [{
                    extend: 'selectNone',
                    text: 'Link Check',
                    action: function (e, dt, node, config) {
                        var l = table.rows(".selected").data()[0]
                        window.open(`http://${l.ip}:${l.port}`);

                    }
                },
                {
                    extend: 'selectNone',
                    text: 'Approve',
                    action: function (e, dt, node, config) {
                        var l = table.rows(".selected").data()[0]
                        if (l.status !== "PENDING" && l.status !== "REJECT") {
                            Swal.fire({
                                title: 'Only PENDING or REJECT Station can Approve',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Approve it!'
                            }).then(async (result) => {
                                if (result.isConfirmed) vue.acceptStation(l,"add")
                            })
                        }
                    }
                },
                {
                    extend: 'selectNone',
                    text: 'Reject',
                    action: function (e, dt, node, config) {
                        var l = table.rows(".selected").data()[0]
                        if (l.status !== "PENDING") {
                            Swal.fire({
                                title: 'Only PENDING Station can Reject',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "Please tell the reason to reject then confirm it!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Approve it!',
                                input: 'text',

                            }).then((result) => {
                                if (result.isConfirmed)
                                    vue.rejectStation(l.id, result.value)
                            })
                        }
                    }
                },
                {
                    extend: 'selectNone',
                    text: 'Remove From APP',
                    action: function (e, dt, node, config) {
                        var l = table.rows(".selected").data()[0]
                        if (l.status !== "ACTIVE") {
                            Swal.fire({
                                title: 'Only ACTIVE Station can Remove',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "Please confirm to Remove!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Approve it!',

                            }).then((result) => {
                                if (result.isConfirmed)
                                    vue.removeFromPlatform(l)
                            })
                        }

                    }
                },
                {
                    text: 'Sync',
                    action: async function (e, dt, node, config) {
                        Swal.fire({
                            title: 'Are you sure?',
                            text: "Please confirm to Sync all?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, Sync it!',

                        }).then(async (result) => {
                            if (result.isConfirmed)
                            {
                                await $.each(table.rows().data(),async function(id,l){
                                    if(l.status === "ACTIVE")
                                    {
                                        await vue.acceptStation(l,"sync")
                                    }
                                })
                                setTimeout(function(){vue.pushtoUpdate()},3000)
                            }
                        })
                    }
                }
            ]
        })

        table.buttons().container().appendTo("#basic-datatable_wrapper .col-md-6:eq(0)")
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.firestore().collection("softwarehouse").doc("djinth").collection("enroll").onSnapshot(function (querySnapshot) {
                    let insert = []
                    table.clear().draw(true)
                    querySnapshot.forEach(function (doc) {
                        let id = doc.id
                        let l = doc.data()
                        // l.name = `${l.name}<br>${l.description}`
                        if (l.serverType === "sc1")
                            l.type = "ShoutCast1"
                        else if (l.serverType === "sc2")
                            l.type = "ShoutCast2"
                        else if (l.serverType === "ic2")
                            l.type = "Icecast2"
                        // l.logo = `<img src="${l.logo}" width="40">`
                        l.regis = `<span style="display:none;">${+ l.timestamp.toDate()}</span>` + dayjs(l.timestamp.toDate()).locale('th').format('DD MMMM YYYY')

                        let timeupdate = (typeof l.timeupdate !== "undefined") ? +l.timeupdate.toDate() : +l.timestamp.toDate()
                        l.update = `<span style="display:none;">${timeupdate}</span>` + dayjs(timeupdate).locale('th').format('DD MMMM YYYY')
                        l.id = id
                        insert.push(l)
                    })
                    table.rows.add(insert).draw(true)

                })
            } else
                window.location.href = "/login"

        })

    },
    methods: {
        async acceptStation(l,type="add") {
            this.loadshow(true)
            let vue = this
            let streamURL = false
            if (l.serverType === "sc1" || l.serverType === "sc2")
                streamURL = `http://${l.ip}:${l.port}/;`
            else if (l.serverType === "ic2")
                streamURL = `http://${l.ip}:${l.port}/stream`

            let updateItem = {
                name: l.name,
                genre: l.genre,
                description: l.description,
                logo: l.logo,
                serverType: l.serverType,
                port: l.port,
                ip: l.ip,
                lastupdate: Number(new Date()),
                enrollDate: Number(l.timestamp.toDate()),
                slide: l.slide || null,
                owner : l.uid,
                s: streamURL,
            }
            await firebase.database().ref('api/stations').child(l.id).set(updateItem, function (error) {
                if (error)
                    vue.g_errorMsg(error.message)
                else
                {
                    if(type === "add")
                    Swal.fire('Successful', `Station ${updateItem.name} has been approve`, 'success')
                }
            })
            /// update status

            if(type === "add")
            {
                await firebase.firestore().collection("softwarehouse").doc("djinth").collection("enroll").doc(l.id).update({
                    status: "ACTIVE",
                    reason: "ACCEPT"
                }).catch(error => {
                    vue.g_errorMsg(error.message)
                })
                await this.pushtoUpdate()

                let chatData = {
                    msg: `ยินดีต้อนรับสถานี ${l.name} เข้าสู่ DJ Streaming Thailand`,
                    time: firebase.database.ServerValue.TIMESTAMP,
                    name: "DJ Streaming BOT",
                }
                firebase.database().ref(`chat/${l.id}/messages`).push(chatData)
            }
            this.loadshow(false)

        },
        async rejectStation(id, reason = "") {
            this.loadshow(true)
            await firebase.firestore().collection("softwarehouse").doc("djinth").collection("enroll").doc(id).update({
                status: "REJECT",
                reason: reason
            }).catch(error => {
                vue.g_errorMsg(error.message)
            })
            this.loadshow(false)
        },
        async removeFromPlatform(l) {
            let id = l.id
            this.loadshow(true)
            await firebase.database().ref('api/stations').child(id).remove()
            await firebase.database().ref('api/metadata').child(id).remove()
            await firebase.database().ref('api/playlists').child(id).remove()
            await firebase.database().ref('api/application/djcore').child(id).remove()
            await firebase.database().ref('chat').child(id).remove()
            if(l.domain) // ถ้ามีโดเมนด้วย
            {
                if(l.domain.subdj) // ถ้ามีซับโดเมน
                {
                    let dotTodash = l.domain.subdj.replace(/\./g,"-")
                    await firebase.database().ref('api/route').child(dotTodash).remove() // ลบโดเมนออก (trigger จะลบออกจาก cloudflare ให้อีกที)
                }
            }
            await this.pushtoUpdate()
            await this.rejectStation(id)
            this.loadshow(false)
        },
        async pushtoUpdate() {
            await $.get("http://139.5.145.31:3000/stationupdate")
        }
    }
}
</script>

<style>
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

table.dataTable tbody>tr.selected {
    color: white
}

.dt-buttons {
    margin-bottom: 10px !important;
}
</style>
